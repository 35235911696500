<template>
    <v-container class="pa-0" fluid>
        <page-title
                :heading="$t('settings.lang_nav_usergroups') "
                :icon="icon"
                :subheading="$t('settings.lang_nav_usergroups')"
                show-add-button
                :permissionAdd="this.$store.getters['permissions/checkPermission']('set_permissions')"
        ></page-title>
        <div class="app-main__inner">
            <user-group-component />
        </div>
    </v-container>
</template>

<script>
    //components
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import UserGroupComponent from "../../../../components/settings/basic/usergroup/UserGroupComponent";

    export default {
        components: {
            UserGroupComponent,
            PageTitle,

        },
        data: () => ({
            icon: "pe-7s-users icon-gradient bg-tempting-azure"
        })
    };
</script>

<style>
</style>